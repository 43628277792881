<template>
  <v-form id="formCliente" ref="formCliente" v-model="validFormCliente">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex xs12 lg6>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                label="Codice univoco fatturazione*"
                v-model="cliente.codice"
                name="codice"
                :rules="[$rules.required, $rules.maxlength(12)]"
              />
              <v-text-field
                label="Ragione sociale*"
                v-model="cliente.ragione_sociale"
                name="ragione_sociale"
                :rules="[$rules.required, $rules.maxlength(200)]"
              />
              <v-text-field
                label="Codice fiscale*"
                v-model="cliente.codice_fiscale"
                name="codice_fiscale"
                :rules="[$rules.required, $rules.maxlength(16)]"
              />
              <v-text-field
                label="Partita IVA*"
                v-model="cliente.partita_iva"
                name="partita_iva"
                :rules="[$rules.required, $rules.maxlength(11)]"
              />
              <v-select
                label="% IVA*"
                :items="ivas"
                item-text="label"
                item-value="cod_iva"
                v-model="cliente.iva_id"
                name="iva_id"
                :rules="[$rules.required]"
                />
              <v-select
                label="Pagamento"
                :items="tipipag"
                item-text="nome_metodo"
                item-value="id"
                name="tipo_pagamento_id"
                v-model="cliente.tipo_pagamento_id" />
              <v-text-field
                label="Indirizzo mail DDT*"
                v-model="cliente.mail_ddt"
                name="mail_ddt"
                :rules="[$rules.required, $rules.email]"
              />
              <v-text-field
                label="Forfait magazzino"
                v-model.number="cliente.magazzino_forfait"
                :rules="[$rules.numeric]"
              />
              <currency-input
                v-model="cliente.magazzino_prezzo_in"
                label="Prezzo unitario ingresso magazzino*"
                :rules="[$rules.required]"
                name="Prezzo_in"
              />
              <currency-input
                v-model="cliente.magazzino_prezzo_out"
                label="Prezzo unitario uscita magazzino*"
                :rules="[$rules.required]"
                name="Prezzo_out"
              />
              <currency-input
                v-model="cliente.magazzino_prezzo_stock"
                label="Prezzo unitario stock magazzino*"
                :rules="[$rules.required]"
                name="Prezzo_stock"
              />
              <v-select
                label="Stato"
                :items="stati"
                item-text="nome"
                item-value="id"
                v-model="cliente.attivo"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 lg6>
          <v-layout row wrap>
            <v-flex xs12>
              <v-text-field
                label="Indirizzo*"
                v-model="cliente.indirizzo"
                name="indirizzo"
                :rules="[$rules.required, $rules.maxlength(200)]"
              />
              <v-text-field
                label="Città*"
                v-model="cliente.citta"
                name="citta"
                :rules="[$rules.required, $rules.maxlength(200)]"
              />
              <v-text-field
                label="CAP*"
                v-model="cliente.cap"
                name="cap"
                :rules="[$rules.required, $rules.maxlength(5)]"
              />
              <v-text-field
                label="Provincia*"
                v-model="cliente.provincia"
                name="provincia"
                :rules="[$rules.required, $rules.maxlength(2)]"
              />
              <v-text-field
                label="Stato*"
                v-model="cliente.stato"
                name="stato"
                :rules="[$rules.required, $rules.maxlength(200)]"
              />
              <v-text-field
                label="Indirizzo mail PEC*"
                v-model="cliente.mail_pec"
                name="mail_pec"
                :rules="[$rules.required, $rules.email]"
              />
              <v-text-field
                label="Indirizzo mail fattura*"
                v-model="cliente.mail_fattura"
                name="mail_fattura"
                :rules="[$rules.required, $rules.email]"
              />
              <currency-input
                v-model="cliente.marca"
                label="Marca da bollo"
                :rules="[$rules.maxlength(6)]"
                name="marca"
                precision="2"
              />
              <currency-input
                v-model="cliente.magazzino_prezzo_pallet"
                label="Prezzo unitario pallet in uscita*"
                :rules="[$rules.required]"
                name="Prezzo_pallet"
              />
              <currency-input
                v-model="cliente.magazzino_prezzo_picking"
                label="Prezzo unitario picking*"
                :rules="[$rules.required]"
                name="Prezzo_picking"
              />
              <currency-input
                v-model="cliente.magazzino_prezzo_muestra"
                label="Prezzo unitario muestra*"
                :rules="[$rules.required]"
                name="Prezzo_muestra"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <!--v-layout row wrap v-if="(cliente.fornitori && cliente.fornitori.length)">
        <v-flex>
          <h3>Fornitori associati</h3>
          <template v-for="(f, index) in cliente.fornitori">
            <v-chip class="chip--select-multi" :key="'forn-'+index">
              {{ f.nome }}
            </v-chip>
          </template>
        </v-flex>
      </v-layout-->
      <v-layout row wrap>
        <v-flex>
          <v-btn
            class="ml-0"
            color="primary"
            :disabled="!validFormCliente"
            id="salvaCliente"
            @click.native="save()">
            Salva
          </v-btn>
          <v-btn
            color="error"
            v-if="cliente._id"
            @click="confirm = true">
            Elimina cliente
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="confirm" persistent max-width="250" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="headline">Sicuro?</v-card-title>
        <v-card-text>
          Stai per eliminare il cliente <strong>{{cliente.ragione_sociale}}</strong><br/>
          Confermi?
        </v-card-text>
        <v-card-actions>
          <v-btn @click.native="confirm = false">Annulla</v-btn>
          <v-btn color="error" @click.native="remove">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import currencyInput from '@/components/common/CurrencyInput'
import { $EventBus } from '@/eventBus'
export default {
  props: {
    cliente: {} // era Object
  },
  data: () => ({
    validFormCliente: false,
    stati: [{ id: 1, nome: 'Attivo' }, { id: 0, nome: 'Non attivo' }],
    ivas: [],
    tipipag: [],
    confirm: false
  }),
  components: {
    currencyInput
  },
  computed: {
    statoFattureOnline () {
      if (this.cliente._id && this.cliente.id_esterno) return 2
      else if (this.cliente._id && !this.cliente.id_esterno) return 1
      else return 0
    }
  },
  methods: {
    /* fattureOnline () {
      this.$http({
        method: 'post',
        url: this.cliente.id_esterno ? '/fattureonline/cliente/modifica' : '/fattureonline/cliente/crea',
        data: { id: this.cliente }
      }).then((response) => {
        if (!this.cliente.id_esterno) {
          $EventBus.$emit('message', { type: 'success', text: 'Cliente salvato su fatture in cloud' })
          this.cliente.id_esterno = response.data.id
        } else {
          $EventBus.$emit('message', { type: 'success', text: 'Cliente modificato su fatture in cloud' })
        }
        this.save()
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare il cliente su fatture in cloud' })
      })
    }, */
    getIvas () {
      // this.$plsqlMethod('cliente', 'get_iva', {})
      this.$http({
        method: 'get',
        url: '/fattureonline/iva' })
        .then(response => {
          this.ivas = response.data
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit({ type: 'error', text: 'Errore lettura IVA' })
        })
    },
    getTipipag () {
      // this.$plsqlMethod('cliente', 'get_tipip', {})
      this.$http({
        method: 'get',
        url: '/fattureonline/modpag' })
        .then(response => {
          this.tipipag = response.data
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit({ type: 'error', text: 'Errore modalità pagamento' })
        })
    },
    remove () {
      this.$plsqlDelete('cliente', this.cliente._id)
        .then(response => {
          $EventBus.$emit('message', {
            type: 'success',
            text: 'Cliente eliminato'
          })
          this.confirm = false
          this.$router.push({ name: 'clienti' })
        })
        .catch(err => {
          if (err.data.errCode === 'ORA-02292') {
            $EventBus.$emit('message', { type: 'error', text: 'Non è possibile cancellare il Cliente perché usato' })
          } else {
            $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
          }
          this.confirm = false
        })
    },
    save () {
      if (this.$refs.formCliente.validate()) {
        this.cliente.aliquota = this.ivas.find((el) => { return el.cod_iva === this.cliente.iva_id }).valore_iva
        this.$plsqlSave('cliente', this.cliente)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Cliente salvato'
            })
            if (this.$route.params.id === 'add') {
              $EventBus.$emit('cliente:added', response.data)
            }
          })
          .catch(err => {
            console.log(err)
            $EventBus.$emit({ type: 'error', text: 'Errore salvataggio cliente' })
          })
      }
    }
  },
  mounted () {
    this.getIvas()
    this.getTipipag()
    $EventBus.$emit('message', { type: 'info', text: 'Ad ogni salvataggio i dati del cliente verranno inviati a FattureInCloud.it' })
  }
}
</script>
