<template>
  <v-dialog
    v-model="cliente"
    fullscreen
    transition="dialog-bottom-transition"
    :overlay="false"
    scrollable
    persistent>
    <v-card>
      <v-toolbar class="grey lighten-3">
        <v-toolbar-title>
          <span v-if="cliente._id">{{cliente.ragione_sociale}}</span>
          <span v-if="!cliente._id">Nuovo cliente</span>
          <item-updated
            v-if="cliente._id"
            :mdate="cliente.modifica"
            :muser="cliente.utente" />
          </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$router.push({ name: 'clienti'})"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-stepper non-linear light v-model="step">
          <v-stepper-header v-show="cliente._id">
            <template v-for="(step, index) in steps">
              <v-stepper-step
                color="primary"
                :key="index + 'step'"
                :step="step.number"
                @click.native="changeStep(index, $event)"
                editable>{{ step.name }}</v-stepper-step>
              <v-divider :key="index + 'divider'" />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content :step="1">
              <!-- primo step direttamente qui -->
              <cliente-scheda :cliente="cliente" />
            </v-stepper-content>
            <v-stepper-content v-for="(s, index) in steps" :key="index" :step="s.number" v-if="s.number > 1">
              <!-- gli altri step sono rotte figlie -->
              <router-view v-if="s.number === step" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions v-if="cliente._id">
        <v-container fluid>
          <v-layout>
            <v-flex>
              <template v-for="(s, index) in steps">
                <div v-if="s.number === step"  :key="index">
                  <span class="mr-4" v-if="steps[index - 1]">
                    <v-btn color="primary" small fab depressed dark @click="changeStep(index - 1)">
                      <v-icon>chevron_left</v-icon>
                    </v-btn>
                    <span class="body-2 hidden-xs-only">
                      {{ steps[index - 1].name }}
                    </span>
                  </span>
                  <span class="ml-4" v-if="steps[index + 1]">
                    <span class="body-2 hidden-xs-only">
                      {{ steps[index + 1].name }}
                    </span>
                    <v-btn color="primary" small fab depressed dark @click="changeStep(index + 1)">
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </span>
                </div>
              </template>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from '@/eventBus'
import itemUpdated from '@/components/common/ItemUpdated'
import clienteScheda from '@/components/anagrafica/clienti/ClienteScheda'

export default {
  data: () => ({
    cliente: {},
    step: 1,
    steps: [
      { number: 1, name: 'Scheda cliente', route: 'cliente' },
      { number: 2, name: 'Coordinate bancarie', route: 'clienteCoordinate' },
      { number: 3, name: 'Destinazioni e provenienze', route: 'clienteDestinazioni' },
      { number: 4, name: 'Voci fattura', route: 'clienteVoci' },
      { number: 5, name: 'Opere - prodotti', route: 'clienteOpere' },
      { number: 6, name: 'Lavorazioni', route: 'clienteLavorazioni' },
      { number: 7, name: 'Fornitori', route: 'clienteFornitori' }
    ]
  }),
  components: {
    itemUpdated,
    clienteScheda
  },
  methods: {
    changeStep (index) {
      this.$router.push({ name: this.steps[index].route, params: { id: this.cliente._id } }) // prima vai in modo che la rotta di arrivo inizi già a leggere i dati
      this.step = this.steps[index].number
    }
  },
  mounted () {
    if (this.$route.params.id !== 'add') {
      this.$plsqlRead('cliente', this.$route.params.id)
        .then(response => {
          this.cliente = response.data
          if (this.$route.name !== 'cliente') { // arrivo diretto al path di uno step
            this.step = this.steps.find(step => step.route === this.$route.name).number
          }
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', {
            type: 'error',
            text: 'Errore lettura cliente'
          })
        })
    }
    $EventBus.$on('cliente:added', (cliente) => {
      this.cliente = cliente
      this.$router.push({ name: 'cliente', params: { id: cliente._id } })
    })
  }
}
</script>
